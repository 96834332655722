<template>
  <div class="courseSelection">
    <!-- <div class="filtrate">
      <div class="filtrate-content">
        <div class="filtrate-list classes">
          <div class="filtrate-title">行业：</div>
          <div class="option-list clearfix">
            <span
              class="filtrate-option"
              :class="categorySelect === 0 ? 'active' : ''"
              @click="switchOne(0)"
              >不限</span
            >
            <span
              class="filtrate-option"
              v-for="item in category"
              :key="item.id"
              :class="Number(categorySelect.id) == item.id ? 'active' : '11'"
              @click="switchOne(item)"
              >{{ item.name }}</span
            >
          </div>
        </div>
        <div class="filtrate-list">
          <div class="filtrate-title clearfix">领域：</div>
          <div class="option-list">
            <span
              class="filtrate-option"
              :class="category_id === 0 ? 'active' : ''"
              @click="categoryListBtn(0)"
              >不限</span
            >
            <span
              class="filtrate-option"
              v-for="subItem in categorySelect.child"
              :key="subItem.id"
              :class="category_id == subItem.id ? 'active' : ''"
              @click="categoryListBtn(subItem.id)"
              >{{ subItem.name }}</span
            >
          </div>
        </div>
      </div>
    </div> -->
    <div class="result-box">
      <ul class="result-list">
        <li
          class="result-info"
          v-for="item in list"
          :key="item.id"
        >
          <img class="result-img" :src="item.image" :key="item.image" />
          <div class="result-content">
            <div class="result-title">{{ item.name }}</div>
            <div
              class="result-describe introduction"
              v-html="item.introduction"
            ></div>
            <div class="result-lecturers">{{ item.content }}</div>
            <div class="btn"><p @click="sightBtn(item.id)">报名</p></div>
          </div>
        </li>
      </ul>
      <pagination
        :num="num"
        :limit="limit"
        @getNew="getNew"
        v-if="paginationUpdata"
      ></pagination>
    </div>
  </div>
</template>
<script>
import pagination from "@/components/pc/pagination.vue";
import { mapMutations } from "vuex";

export default {
  name: "CourseSelection",
  data() {
    return {
      paginationUpdata: true, //重新渲染分页
      pageType: 0, //1.选课中心 2.系列课
      category: [], //筛选条件
      categorySelect: {}, //选中条件
      category_id: 0, //分类id
      list: [], //课程
      num: 0, //数据总个数
      limit: 10, //一页显示几个
      nowPage: 1, //当前页码
      titleName: "",
      module_id: "",
      websiteData: "",
    };
  },
  components: {
    pagination,
  },
  mounted() {
    // this.titleName = this.$route.query.name;
    // this.module_id = this.$route.query.id;
    // if (this.module_id) {
    this.getCategoryList();
    this.getModelList();
    // }
    this.websiteData = JSON.parse(localStorage.getItem("websiteState"));
  },
  methods: {
    ...mapMutations(["ShowLoginModal", "ShowTipModal", "CloseTipModal"]), //登录弹窗

    async getCategoryList() {
     
      await this.$axios.post(`/v1/textCourse/category`).then((res) => {
        this.category = res.data;
        let pid = this.$route.query.pid;
        let id = this.$route.query.id || 0;

        if (pid) {
          for (let i in res.data) {
            if (res.data[i].id == pid) {
              this.categorySelect = res.data[i];
            }
          }
        } else {
          this.categorySelect = 0;
        }

        this.categoryListBtn(id); //点击二级分类
      });
    },
    //切换一级分类
    switchOne(item) {
      this.categorySelect = item;
      this.category_id = 0;
      this.categoryListBtn(0);  //点击二级分类
    },
    //点击二级分类
    categoryListBtn(id) {
      this.category_id = id;
      this.getModelList(); //获取课程列表
    },

    async getModelList(moduleData) {
      let category_id = this.category_id;

      if (category_id == 0) {
        category_id = this.categorySelect.id;
      }

      let data1 = await this.$axios.post(`/v1/openClass/list`, {
        category_id,
        pre_page: this.limit,
        page: this.nowPage,
      });
      this.list = data1.data.data;
      this.num = data1.data.total;
    },
    // 报名
    sightBtn(id) {
      let _this = this;
      _this.ShowTipModal({
        text: "确定报名该班级？", //提示弹窗内容
        showBtns: true,
        subBtnText: "报名",
        confirmFun: function () {
          _this.CloseTipModal();
          // _this.payNowBtn(_this.courseInfo.detail.id);
          this.$axios
            .post(
              `/v1/openClass/joinOpenClass`,
              { class_id: id },
              { useLog: true }
            )
            .then((res) => {
              _this.ShowTipModal({
                text: res.msg, //提示弹窗内容
              });
            });
        },
      });
    },
    //分页
    getNew(nowPage) {
      this.nowPage = nowPage;

      this.getModelList();
    },
  },
  watch: {
    //监听路由判断换题
    $route() {
      this.$router.go(0);
    },
  },
};
</script>
<style scoped lang="scss">
.courseSelection {
  background-color: #f7f7f7;
}
.filtrate {
  width: 100%;
  background-color: #fff;
  margin-bottom: 24px;
  .filtrate-content {
    line-height: 50px;
    font-size: 20px;
    padding-left: 15px;
  }
}
.sorting {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 25px;
  display: flex;
  align-items: center;
  .drop-down-box {
    margin-right: 50px;
  }
  .has-arrow {
    font-size: 14px;
    color: #999;
    margin-right: 50px;
    min-height: 18px;
    padding-right: 12px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    &::after {
      content: "";
      display: block;
      width: 8px;
      height: 18px;
      background: url(~@/assets/arrows-lang-ico.png) no-repeat right center;
      background-size: auto 18px;
      position: absolute;
      right: 0;
      top: 0;
    }
    &.asc::after {
      transform: rotate(180deg);
    }
  }
  .result-sum {
    margin-left: auto;
    display: flex;
    align-items: center;
    .sum-num {
      font-size: 14px;
      color: #666;
    }
    .turn {
      width: 24px;
      height: 24px;
      background: url(~@/assets/arrows-right.png) no-repeat right center;
      background-size: 100%;
      cursor: pointer;
      &.pre {
        transform: rotate(180deg);
        margin: 0 4px;
      }
    }
  }
}
.result-box {
  padding-bottom: 88px;
}
.result-list {
  box-sizing: border-box;
  width: 1200px;
  min-height: 700px;
  padding: 24px;
  margin: 0 auto;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-flow: wrap;
  div.btn{
    p{
    width: 70px;
    height: 26px;
    background: #9f1106;
    color: #fff;
    font-size: 12px;
    line-height: 26px;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    }
  }
}
.result-info {
  box-sizing: border-box;
  width: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  &:hover {
    box-shadow: 0px 0px 14px 0px rgba(0, 12, 40, 0.14);
    border-radius: 8px;
  }
}
.result-img {
  width: 120px;
  height: 160px;
  background: rgba(0, 0, 0, 0.02);
  border-radius: 8px;
}
.result-content {
  width: 578px;
  height: 156px;
  margin-left: 28px;
  position: relative;
}
.result-title {
  display: block;
  font-size: 16px;
  font-weight: 800;
  color: #333;
  text-shadow: 0px 0px 14px rgba(0, 12, 40, 0.14);
  margin-bottom: 10px;
  padding-top: 10px;
}
.result-marks {
  overflow: hidden;
  margin-bottom: 10px;
  .mark {
    padding: 0 8px;
    height: 18px;
    line-height: 18px;
    background: rgba(37, 78, 212, 0.14);
    box-shadow: 0px 0px 14px 0px rgba(0, 12, 40, 0.14);
    border-radius: 10px 2px 10px 2px;
    font-size: 12px;
    color: #254ed4;
    text-align: center;
    float: left;
    margin-right: 10px;
  }
}
.result-lecturers {
  font-size: 14px;
  color: #666;
  text-shadow: 0px 0px 14px rgba(0, 12, 40, 0.14);
  margin-bottom: 23px;
}
.result-describe {
  margin: 15px 0;
  max-height: 48px;
  font-size: 14px;
  color: #999;
  line-height: 24px;
  text-shadow: 0px 0px 14px rgba(0, 12, 40, 0.14);
  //多行省略号
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  // position: absolute;
  // left: 0;
  // bottom: 10px;
}
.result-pice-paynum {
  margin-left: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.result-pice {
  width: 100%;
  font-size: 22px;
  font-weight: 900;
  color: #cd1b14;
  text-shadow: 0px 0px 14px rgba(0, 12, 40, 0.14);
  margin-bottom: 5px;
}
.result-paynum {
  width: 100%;
  font-size: 12px;
  font-weight: 900;
  color: #999;
  text-shadow: 0px 0px 14px rgba(0, 12, 40, 0.14);
  text-align: center;
}
.page-wrap {
  width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  padding-bottom: 20px;
  ::v-deep .pointer.hover {
    background: none;
  }
}
</style>